<template>
  <div>
      <!-- Hero -->
    <base-page-heading
      title="Cotización"
      subtitle="| Crear cotización"
      class="heading-block"
    >
    </base-page-heading>

    <div>
        <base-block rounded title="">
            <div class="block-content font-size-sm">
                <form ref="form" @submit.stop.prevent="handleSubmit">
                    
                    <b-row class="ml-4 mt-1">                       
                        <b-col sm="3">
                            <b-form-group label-for="no_cliente">
                                <template #label>
                                    Cotización : <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    v-model="form.cotizacion_id"
                                    disabled
                                    size="sm"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>  
                        <b-col sm="3">
                            <b-form-group label-for="no_cliente">
                                <template #label>
                                    Cliente : <span class="text-danger">*</span>
                                </template>
                                <select class="form-control" @change="ChangePreCotizacion($event)" v-model="form.client_id">
                                    <option :value="data.id" v-for="(data, index) in clientes" :key="index">
                                        {{ data.nombre_comercial }}
                                    </option>
                                </select>
                            </b-form-group>
                        </b-col>                      
                    </b-row>
                    <b-row class="ml-4 mt-1">
                        <b-col sm="3">
                            <b-form-group label-for="no_cliente">
                                <template #label>
                                    # de piezas : <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    v-b-tooltip.hover title="Escriba el número de piezas"
                                    v-model="form.num_piezas"
                                    size="sm"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <br />
                    <template v-if="this.form.cotizacion_id != ''">
                            <b-row class="ml-4 mt-1">
                                <b-col sm="3" style="left: 70%;">
                                        <b-form-group label-for="no_cliente">
                                            <template #label>
                                                SELECCIONE MATERIA PRIMA O SERVICIO 
                                            </template>
                                            <select  :disabled="form.num_piezas==''" v-model="select_option" @change="Option($event)" class="form-control">
                                                <option value="1">SERVICIO</option>
                                                <option value="2">MATERIA PRIMA</option>
                                            </select>
                                            <span  style="color:red;" v-if="form.num_piezas==''">Escriba el # de piezas primero</span>
                                        </b-form-group>
                                </b-col>
                                    
                                <!-- Small Block Modal -->
                                <b-modal
                                    id="modal-items"
                                    size="xl"
                                    body-class="p-0"
                                    hide-footer
                                    hide-header
                                >
                                    <div class="block block-rounded block-themed block-transparent mb-0">
                                        <div class="block-header bg-primary-dark">
                                            <h3 class="block-title">
                                            <i class="fa fa-user mr-1"></i> Lista
                                            </h3>
                                            <div class="block-options">
                                            <button
                                                type="button"
                                                class="btn-block-option"
                                                @click="ShowModalProducto()"
                                            >
                                                
                                            </button>
                                            <i style="cursor:pointer;" class="fa fa-fw fa-times" @click="$bvModal.hide('modal-item')"></i>
                                            </div>
                                        </div>
                                        <div class="block-content font-size-sm">
                                            <div class="table-responsive">
                                                <template v-if="select_option==1">
                                                    <b-table  @row-clicked="SelectItemsServicios" :items="servicios" :fields="fields_servicios" :current-page="currentPage" :per-page="perPage" responsive striped hover >
                                                    </b-table>
                                                </template>
                                                <template v-else>
                                                    <b-table  @row-clicked="SelectItemsMateriaP" :items="materiap" :fields="fields_materiap" :current-page="currentPage" :per-page="perPage" responsive striped hover >
                                                    </b-table>
                                                </template>
                                                
                                            </div>
                                        </div>
                                        <div class="block-content block-content-full text-right border-top">
                                            <b-button
                                            variant="alt-primary"
                                            class="mr-1"
                                            @click="$bvModal.hide('modal-items')"
                                            >Cerrar</b-button
                                            >
                                        </div>
                                    </div>
                                </b-modal>
                                <!-- END Small Block Modal -->
                            </b-row>
                            <b-row>
                                <div class="container">
                                    <div class="row">
                                        <div class="col-5" id="linea">
                                            <hr>
                                        </div>
                                        <div class="col-2" id="titulo">
                                            <p>Materia Prima</p>
                                        </div>
                                        <div class="col-5" id="linea">
                                            <hr>
                                        </div>
                                    </div>
                                </div>
                            </b-row>
                            <b-row class="ml-4 mt-12">
                                <b-col sm="12">
                                    <div class="accordion" role="tablist">
                                        <b-card no-body class="mb-1" v-for="(data, index) in select_list_items_materiap" :key="data.index">
                                            <b-card-header header-tag="header" class="p-1" role="tab">
                                                <b-button id="button_accordion" block v-b-toggle="'accordion'+index" variant="info">{{data.name}} - {{data.descripcion}}</b-button>
                                                <i class="fa fa-trash" @click="DeletedSelectMateriaP(index)" style="cursor: pointer;position: absolute;float: left;left: 90%;border-radius: 20px;color: white;margin-top: -26px;"></i>
                                                <i class="fa fa-save" @click="Cotizacion()" style="cursor: pointer;position: absolute;float: left;left: 93%;border-radius: 20px;color: white;margin-top: -26px;"></i>
                                            </b-card-header>
                                            <b-collapse :id="'accordion'+index" accordion="my-accordion" role="tabpanel">
                                                <b-card-body>
                                                    <br><br>
                                                    <b-row class="ml-4 mt-12">
                                                        <b-col sm="12">
                                                            <b-row style="padding: 1%;width: 99%;border-width: 1px;border-style: solid;border-color: #d0d0d0;border-radius: 5px;"> 
                                                                    <b-col sm="12">
                                                                        <b-form-group>
                                                                            <template #label>
                                                                                ESPECIFICACIONES
                                                                            </template>
                                                                        </b-form-group>
                                                                    </b-col>
                                                                     <b-col sm="2">
                                                                        <b-form-group>
                                                                            <template #label>
                                                                                Código
                                                                            </template>
                                                                            <b-form-input
                                                                                disabled
                                                                                v-model="data.name"                                                                                
                                                                                size="sm"
                                                                            ></b-form-input>
                                                                        </b-form-group>
                                                                    </b-col> 
                                                                    <b-col sm="3">
                                                                        <b-form-group>
                                                                            <template #label>
                                                                                Descripción
                                                                            </template>
                                                                            <b-form-input
                                                                                disabled
                                                                                v-model="data.descripcion"                                                                                
                                                                                size="sm"
                                                                            ></b-form-input>
                                                                        </b-form-group>
                                                                    </b-col> 
                                                                    <b-col sm="2">
                                                                        <b-form-group>
                                                                            <template #label>
                                                                                Largo Estándar
                                                                            </template>
                                                                            <b-form-input
                                                                                disabled
                                                                                v-model="data.largo_estandar"                                                                                
                                                                                size="sm"
                                                                            ></b-form-input>
                                                                        </b-form-group>
                                                                    </b-col> 
                                                                    <b-col sm="2">
                                                                        <b-form-group label-for="largo_solicitado">
                                                                            <template #label>
                                                                                Largo Estándar (mm)
                                                                            </template>
                                                                            <b-form-input
                                                                                disabled
                                                                                :value="data.largo_estandar*10"
                                                                                size="sm"
                                                                            ></b-form-input>
                                                                        </b-form-group>
                                                                    </b-col> 
                                                                    <b-col sm="2">
                                                                        <b-form-group label-for="largo_solicitado">
                                                                            <template #label>
                                                                                Familia
                                                                            </template>
                                                                            <b-form-input
                                                                                disabled
                                                                                value="ACERO COMERCIAL"
                                                                                size="sm"
                                                                            ></b-form-input>
                                                                        </b-form-group>
                                                                    </b-col>  
                                                                    <b-col sm="1">
                                                                        <b-form-group label-for="kilos_solicitados">
                                                                            <template #label>
                                                                                PV Kilo
                                                                            </template>
                                                                            <b-form-input
                                                                                disabled
                                                                                v-model="data.pv"
                                                                                size="sm"
                                                                            ></b-form-input>
                                                                        </b-form-group>
                                                                    </b-col>                                                         
                                                            </b-row>
                                                            <br><br>

                                                            <b-row > 
                                                                    <b-col sm="8" >
                                                                      <b-row>
                                                                        <b-col sm="5" >
                                                                          <!-- seccion 1 -->
                                                                          <b-row>
                                                                            <b-col sm="12" >
                                                                              <b-form-group>
                                                                                <template #label>
                                                                                    LAMINAS/PLACAS A COTIZAR
                                                                                </template>
                                                                              </b-form-group>
                                                                            </b-col>
                                                                            <b-col sm="6" >
                                                                              <b-form-group label-for="largo_solicitado">
                                                                                <template #label>
                                                                                    # Laminas/Placas
                                                                                </template>
                                                                                <b-form-input
                                                                                    v-model="data.num_placas"
                                                                                    v-b-tooltip.hover title="Escriba el número de laminas/placas"
                                                                                    size="sm"
                                                                                ></b-form-input>
                                                                              </b-form-group>
                                                                            </b-col>
                                                                          </b-row>
                                                                        </b-col>
                                                                        <b-col sm="7" >
                                                                          <b-row>
                                                                            <!-- seccion 2 -->
                                                                            <b-col sm="12">
                                                                                <b-form-group>
                                                                                    <template #label>
                                                                                        MATERIAL POR CORTE A COTIZAR
                                                                                    </template>
                                                                                </b-form-group>
                                                                            </b-col>
                                                                            <b-row>
                                                                                    <b-col sm="5">
                                                                                        <b-form-group label-for="largo_solicitado">
                                                                                            <template #label>
                                                                                                Largo Solicitado (mm)
                                                                                            </template>
                                                                                            <b-form-input
                                                                                            v-b-tooltip.hover title="Escriba el largo solicitado (mm)"
                                                                                            id="largo_solicitado"
                                                                                            v-model="data.largo_solicitado"
                                                                                            size="sm"
                                                                                            ></b-form-input>
                                                                                        <!--    Precio:
                                                                                            $ {{ ((data.largo_solicitado * data.ancho) * data.peso_mm2) * data.pv }}
                                                                                        --></b-form-group>
                                                                                    </b-col>
                                                                                    <b-col sm="5">
                                                                                        <b-form-group label-for="ancho_solicitado">
                                                                                            <template #label>
                                                                                                Ancho solicitado (mm)
                                                                                            </template>
                                                                                            <b-form-input
                                                                                            v-b-tooltip.hover title="Escriba el ancho solicitado (mm)"
                                                                                            id="largo_solicitado"
                                                                                            v-model="data.ancho_solicitado"
                                                                                            size="sm"
                                                                                            ></b-form-input>
                                                                                        </b-form-group>
                                                                                    </b-col>    
                                                                            </b-row>
                                                                            <!-- fin seccion 2 -->
                                                                          </b-row>
                                                                        </b-col>
                                                                      </b-row>

                                                                      <b-col sm="12" class="mt-4">
                                                                        <b-form-group>
                                                                            <template #label class="text-danger">
                                                                                TOTAL KILOS
                                                                            </template>
                                                                        </b-form-group>
                                                                      </b-col>

                                                                        <b-row>
                                                                            <b-col sm="4">
                                                                                    <b-form-group label-for="kilos_solicitados">
                                                                                        <template #label>
                                                                                            Kilos Totales
                                                                                        </template>
                                                                                        <b-form-input
                                                                                            disabled
                                                                                            :value="data.kilos_totales = data.num_placas * data.peso_teorico"
                                                                                            size="sm"
                                                                                        ></b-form-input>
                                                                                    </b-form-group>
                                                                            </b-col>

                                                                            + 

                                                                            <b-col sm="4">
                                                                                <b-form-group label-for="kilos_solicitados">
                                                                                    <template #label>
                                                                                        Kilos Solicitados
                                                                                    </template>
                                                                                    <b-form-input
                                                                                        disabled
                                                                                        :value="data.kilos_solicitados = (data.largo_solicitado * data.ancho_solicitado) * data.peso_mm2"
                                                                                        size="sm"
                                                                                    ></b-form-input>
                                                                                </b-form-group>
                                                                            </b-col> 

                                                                            =

                                                                            <b-col sm="2" class="ml-4">
                                                                                    <b-form-group label-for="total">
                                                                                        <template #label>
                                                                                            Total Kilos
                                                                                        </template>
                                                                                        <b-form-input
                                                                                            :value="data.kilos_totales + data.kilos_solicitados"
                                                                                            disabled
                                                                                            size="sm"
                                                                                        ></b-form-input>
                                                                                    </b-form-group>
                                                                            </b-col>

                                                                        </b-row>                                                                            
                                                                    </b-col>
                                                                    <div class="v-line"></div>
                                                                    <b-col sm="4" >
                                                                        <b-col sm="12">
                                                                            <b-form-group>
                                                                                <template #label>
                                                                                    COSTOS TOTALES
                                                                                </template>
                                                                            </b-form-group>
                                                                        </b-col>
                                                                            <b-row class="ml-4 mt-1">
                                                                                <b-col sm="5">
                                                                                        <b-form-group label-for="total">
                                                                                            <template #label>
                                                                                                Total
                                                                                            </template>
                                                                                            $ {{ data.total_dinero = parseFloat((data.kilos_totales + data.kilos_solicitados) * data.pv).toFixed(2) }}
                                                                                        </b-form-group>
                                                                                </b-col>
                                                                            </b-row>
                                                                            <b-row class="ml-4 mt-1">
                                                                                <b-col sm="5">
                                                                                        <b-form-group label-for="total">
                                                                                            <template #label>
                                                                                                Total x Pza
                                                                                            </template>
                                                                                            $ {{ data.total_x_pieza = parseFloat((data.kilos_totales + data.kilos_solicitados) * data.pv / form.num_piezas).toFixed(2) }}
                                                                                        </b-form-group>
                                                                                </b-col>
                                                                            </b-row>
                                                                            <b-row class="ml-4 mt-1">
                                                                                <b-col sm="5">
                                                                                        <b-form-group label-for="total">
                                                                                            <template #label>
                                                                                                Descuento %
                                                                                            </template>
                                                                                            <select class="form-control" v-model="data.descuento">
                                                                                                <option value="">0%</option>
                                                                                                <option value="5">5%</option>
                                                                                                <option value="10">10%</option>
                                                                                                <option value="20">20%</option>
                                                                                            </select>
                                                                                        </b-form-group>
                                                                                </b-col>
                                                                            </b-row>                                                                            
                                                                            <b-row class="ml-4 mt-4">
                                                                                <b-col sm="8">
                                                                                        <b-form-group label-for="total">
                                                                                            <template #label>
                                                                                                Total (con/sin Descuento)
                                                                                            </template>
                                                                                            $ {{  data.descuento == '' ? data.total_dinero : data.total_dinero - ((data.total_dinero * data.descuento)/100) }}
                                                                                        </b-form-group>
                                                                                </b-col>  
                                                                            </b-row>  
                                                                    </b-col>                                                           
                                                            </b-row>
                                                            <br><br>

                                                            
                                                            <br><br>
                                                        </b-col>
                                                    </b-row>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>
                                    </div>
                                </b-col>
                            </b-row>
                            <b-row>
                                <div class="container">
                                    <div class="row">
                                        <div class="col-5" id="linea">
                                            <hr>
                                        </div>
                                        <div class="col-2" id="titulo">
                                            <p>Servicios</p>
                                        </div>
                                        <div class="col-5" id="linea">
                                            <hr>
                                        </div>
                                    </div>
                                </div>
                            </b-row>
                            <b-row class="ml-4 mt-12">
                                <b-col sm="12">
                                    <div class="accordion" role="tablist">
                                        <b-card no-body class="mb-1" v-for="(data, index) in select_list_items_servicios" :key="data.index">
                                            <b-card-header header-tag="header" class="p-1" role="tab">
                                                <b-button id="button_accordion" block v-b-toggle="'accordion'+index" variant="info">{{data.name}}</b-button>
                                                <i class="fa fa-trash" @click="DeletedSelectServicios(index)" style="cursor: pointer;position: absolute;float: left;left: 90%;border-radius: 20px;color: white;margin-top: -26px;"></i>
                                            </b-card-header>
                                            <b-collapse :id="'accordion'+index" accordion="my-accordion" role="tabpanel">
                                                <b-card-body>
                                                    <br><br>
                                                    <b-row class="ml-4 mt-12">
                                                        <b-row class="ml-2">                                                    
                                                            <b-col sm="4">
                                                                <b-form-group label-for="username">
                                                                    <template #label>
                                                                        Largo Solicitado
                                                                    </template>
                                                                    <b-form-input
                                                                    id="username"
                                                                    v-b-tooltip.hover title="Escriba el largo solicitado"
                                                                    v-model="data.largo_solicitado"
                                                                    size="sm"
                                                                    ></b-form-input>
                                                                </b-form-group>
                                                            </b-col>
                                                            <b-col sm="4">
                                                                <b-form-group label-for="username">
                                                                    <template #label>
                                                                        Kilos Solicitados
                                                                    </template>
                                                                    <b-form-input
                                                                    id="username"
                                                                    v-b-tooltip.hover title="Escriba los kilos solicitados"
                                                                    v-model="data.kilos_solicitados"
                                                                    size="sm"
                                                                    ></b-form-input>
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                        <b-row class="ml-2">                                                    
                                                            <b-col sm="6">
                                                                <b-form-group>
                                                                    <b-form-group label-cols="6" label-cols-lg="12" label-size="sm" label="Total" label-for="input-sm">
                                                                        $./
                                                                    </b-form-group>
                                                                </b-form-group>
                                                            </b-col>
                                                            <b-col sm="6">
                                                                <b-form-group>
                                                                    <b-form-group label-cols="9" label-cols-lg="12" label-size="sm" label="Total Previo" label-for="input-sm">
                                                                        $./
                                                                    </b-form-group>
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                        
                                                    </b-row>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>
                                    </div>
                                </b-col>
                            </b-row>
                            <b-row class="ml-4 mt-12">
                                <b-col sm="12">
                                    <b-form-group label-for="username">
                                        <template #label>
                                            Comentario
                                        </template>
                                        <b-form-textarea
                                        rows="3"
                                        max-rows="6"
                                        size="sm"
                                        ></b-form-textarea>
                                    </b-form-group>
                                </b-col>
                            </b-row>  

                            <b-row class="ml-4 mt-12">
                                <b-row class="ml-2">   
                                    <b-col sm="3">
                                        <b-form-group>
                                            <b-form-group label-cols="6" label-cols-lg="12" label-size="sm" label="Total" label-for="input-sm">
                                                
                                            </b-form-group>
                                        </b-form-group>
                                    </b-col>
                                    <b-col sm="3">
                                        <b-form-group>
                                            <b-form-group label-cols="9" label-cols-lg="12" label-size="sm" label="Total Previo" label-for="input-sm">
                                                
                                            </b-form-group>
                                        </b-form-group>
                                    </b-col>                                                 
                                    <b-col sm="3">
                                        <b-form-group>
                                            <b-form-group label-cols="6" label-cols-lg="12" label-size="sm" label="Total" label-for="input-sm">
                                                
                                            </b-form-group>
                                        </b-form-group>
                                    </b-col>
                                    <b-col sm="3">
                                        <b-form-group>
                                            <b-form-group label-cols="9" label-cols-lg="12" label-size="sm" label="Total Previo" label-for="input-sm">
                                                $./
                                            </b-form-group>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-row>                          
                    </template >
                    <br />
                </form>
            </div>
            <div  class="block-content block-content-full text-right border-top">
                <b-button variant="alt-primary"  class="mr-1"  @click="Cancelar()">Cancelar</b-button>               
                <b-button variant="primary">Guardar</b-button>
            </div>
        </base-block>
    </div>
  </div>
</template>

<script>

    import Servicios from "../../../../api/admin/services/Services";
    import Clientes from "../../../../api/admin/clientes/Clientes";
    import MateriaP from "../../../../api/admin/materia_prima/Materia_Prima";
    import PreCotizacion from "../../../../api/admin/cotizacion/Pre_cotizacion";
    import Cotizacion from "../../../../api/admin/cotizacion/Cotizacion";
    import Swal from "sweetalert2";

    export default {

        data() {
            return {
                currentPage: 1,
                perPage: 5,
                pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
                select_option:'',
                servicios:[],
                materiap:[],
                clientes:[],
                form: {
                    cotizacion_id:'',
                    client_id:'',
                    num_piezas:'',
                    comentarios:'',
                },              
                fields_servicios: [
                    {key: 'id', label: '# ID'},
                    {key: 'nombre', label: 'NOMBRE'},
                    {key: 'costo_ferrebaztan', label: 'COSTO'},
                    {key: 'margen', label: 'MARGEN'},
                    {key: 'pv', label: 'PRECIO VENTA'},
                ],
                fields_materiap: [
                    {key: 'codigo',sortable: true, label: 'CÓDIGO MACRO'},
                    {key: 'descripcion',sortable: true, label: 'DESCRIPCIÓN'},
                    {key: 'materia_prima_family', formatter: value => {
                        return value.name
                    },sortable: true, label: 'FAMILIA'},
                    {key: 'materia_prima_unit', formatter: value => {
                        return value.name
                    },sortable: true, label: 'UNIDAD'},
                    {key: 'service_type', formatter: value => {
                        return value.nombre.length ? value.nombre : 'No especificado'
                    },sortable: true, label: 'SERVICIO'},
                    {key: 'peso_teorico',sortable: true, label: 'PESO TEORICO'},
                    {key: 'maximo',sortable: true, label: 'MÁXIMO'},
                    {key: 'minimo',sortable: true, label: 'MÍNIMO'},
                ],
                select_list_items_servicios: [],
                select_list_items_materiap: [],
            }
        },

        methods: {

            Cancelar(){
                this.$router.push({
                    path: "/cotizacion/list"
                });
            },

            AllClientes() {
            let auth = JSON.parse(localStorage.autentication);
            Clientes.GetAllCustomers(auth)
                .then((response) => {
                this.clientes = response.data;
                console.log(this.clientes);
                })
                .catch((error) => {
                console.log(error.data);
                });
            },

            ChangePreCotizacion(event){
                let cliente = event.target.value
                let auth = JSON.parse(localStorage.autentication);
                PreCotizacion.CreateQuot(cliente, auth)
                .then((response) => {
                    this.form.cotizacion_id = response.data.data.id;
                    localStorage.id_cotizacion = this.form.cotizacion_id;
                })
                .catch((error) => {
                    const obj = error.response.data.errors;
                    Object.entries(obj).forEach( elements => 
                        Swal.fire({
                            title: "Que mal!",
                            text: elements[1][0],
                            icon: "error",
                        })
                    );
                });
            },

            Cotizacion(){
                let auth = JSON.parse(localStorage.autentication);
                Cotizacion.CreateQuot(this.select_list_items_materiap, this.form, auth)
                .then((response) => {
                    Swal.fire({
                        title: "Genial!",
                        text: response.data.message,
                        icon: "success",
                    });
                })
                .catch((error) => {
                    const obj = error.response.data.errors;
                    Object.entries(obj).forEach( elements => 
                        Swal.fire({
                            title: "Que mal!",
                            text: elements[1][0],
                            icon: "error",
                        })
                    );
                });
            },
                            //2
            SelectItemsServicios(data_select){
                let me = this
                if (me.select_list_items_servicios == '') {                    
                    localStorage.lista_items_servicios = JSON.stringify(data_select);
                    let data = JSON.parse(localStorage.lista_items_servicios)
                    me.select_list_items_servicios.push({
                        name :  data.nombre,
                        pv: data.pv
                    });
                    me.$bvModal.hide("modal-items"); 
                    me.select_option = ''; 
                }else{
                    me.select_list_items_servicios.filter(function(element) {
                        //[{2},{3}]         2
                        if(element.id == data_select.id){
                            Swal.fire({
                                title: "Que mal!",
                                text: 'Ya está agregado a la lista',
                                icon: "error",
                            });
                        }else{
                            me.select_list_items_servicios.push({
                                name :  data_select.nombre,
                                pv : data_select.pv
                            });
                            localStorage.lista_items_servicios = me.select_list_items_servicios;
                            console.log(me.select_list_items_servicios);
                            me.$bvModal.hide("modal-items");  
                            me.select_option = '';
                        }
                    });
                }               
            },

            SelectItemsMateriaP(data_select){
                let me = this
                if (me.select_list_items_materiap == '') {                    
                    localStorage.lista_items_materiap = JSON.stringify(data_select);
                    let data = JSON.parse(localStorage.lista_items_materiap)
                    console.log(data_select);
                    me.select_list_items_materiap.push({
                        materia_prima_id: data.id,
                        service_id:data.service_id,
                        name :  data.codigo,
                        descripcion :  data.descripcion,
                        ancho: data.ancho,
                        peso_mm2: data.peso_mm2,
                        pv: data.service_type.pv,
                        peso_teorico: data.peso_teorico,
                        largo_estandar : data.largo,
                        num_placas:0,
                        largo_solicitado:'',
                        ancho_solicitado:'',
                        kilos_solicitados:0,
                        kilos_totales:0,
                        descuento:'',
                        total_dinero:'',
                        total_x_pieza:'',
                    });
                    me.$bvModal.hide("modal-items"); 
                    me.select_option = ''; 
                }else{
                    me.select_list_items_materiap.filter(function(element) {
                        //[{2},{3}]         2
                        if(element.id == data_select.id){
                            Swal.fire({
                                title: "Que mal!",
                                text: 'Ya está agregado a la lista',
                                icon: "error",
                            });
                        }else{
                            me.select_list_items_materiap.push({
                                name :  data_select.codigo,
                                descripcion :  data_select.descripcion,
                                ancho: data_select.ancho,
                                peso_mm2: data_select.peso_mm2,
                                pv: data_select.service_type.pv,
                                peso_teorico: data_select.peso_teorico
                            });
                            localStorage.lista_items_materiap = me.select_list_items_materiap;
                            console.log(me.select_list_items_materiap);
                            me.$bvModal.hide("modal-items");  
                            me.select_option = '';
                        }
                    });
                }               
            },

            DeletedSelectMateriaP(data){
                
                Swal.fire({
                    title: "¿Esta seguro de eliminar de la lista?",
                    text: "¿Eliminar?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: "Sí, eliminar",
                    cancelButtonText: "Cancelar",
                })
                .then(resultado => {
                    if (resultado.value) {
                        this.select_list_items_materiap.splice(data, 1);
                    } else {
                        // Dijeron que no
                        console.log("*NO se elimina la lista*");
                    }
                });
            },

            DeletedSelectServicios(data){
                
                Swal.fire({
                    title: "¿Esta seguro de eliminar de la lista?",
                    text: "¿Eliminar?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: "Sí, eliminar",
                    cancelButtonText: "Cancelar",
                })
                .then(resultado => {
                    if (resultado.value) {
                        this.select_list_items_servicios.splice(data, 1);
                    } else {
                        // Dijeron que no
                        console.log("*NO se elimina la lista*");
                    }
                });
            },
          
            Option(event) {
                let option = event.target.value
                if (option == 1) {
                    let auth = JSON.parse(localStorage.autentication);
                    Servicios.GetAllServices(auth)
                    .then((response) => {
                        this.servicios = response.data;
                        this.$bvModal.show("modal-items");
                    })
                    .catch((error) => {
                        console.log(error.data);
                    });
                }else if (option == 2) {
                    let auth = JSON.parse(localStorage.autentication);
                    MateriaP.GetAllMateriaPrima(auth)
                    .then((response) => {
                        this.materiap = response.data;
                        this.$bvModal.show("modal-items");
                    })
                    .catch((error) => {
                        console.log(error.data);
                    });
                }
            }

        },

        

        mounted() {
            let me = this;
            me.AllClientes();
            
        },
    };



</script>


<style scoped>
    #button_accordion{
        background: #272e38;
        border-color: #272e38;
    }

    #titulo {
        text-align: center;
    }

    hr#linea {
        margin-top: 1rem;
        margin-bottom: 1rem;
        border: 0;
        border-top: 1px solid #030303;
    }

    hr{
        border-top: 1px solid #000000 !important;
    }

    .v-line{
        border-left: solid #e8dbdb;
        height: 57%;
        left: 63%;
        position: absolute;
    }

    .form-group{
      margin-bottom: 5px!important;
    }

</style>