import Api from "../../Api";

const END_POINT_CREATEQUOT = 'user/quots/quote-service-materia-prima/create';
const END_POINT_UPDATEQUOT = 'user/quots/quote-service-materia-prima/update';
const END_POINT_DELETEQUOT = 'user/quots/quote-service-materia-prima/delete';

export default {

    CreateQuot(list_materiaprima, data, auth) {
        return Api.post(END_POINT_CREATEQUOT, {
            'quote_id': data.cotizacion_id,
            'materia_prima_id': list_materiaprima[0].materia_prima_id,
            'service_id': list_materiaprima[0].service_id,
            'descripcion': list_materiaprima[0].descripcion,
            'largo_solicitado': list_materiaprima[0].largo_solicitado,
            'kilos_solicitados': list_materiaprima[0].kilos_solicitados,
            'descuento': list_materiaprima[0].descuento,
            'total': list_materiaprima[0].total_dinero,
            'total_previo': list_materiaprima[0].total_x_pieza
        }, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            }
        });
    },


    UpdateQuot(data, auth) {
        return Api.patch(END_POINT_UPDATEQUOT, {
            'quote_id': data.quote_id,
            'materia_prima_id': data.materia_prima_id,
            'service_id': data.service_id,
            'descripcion': data.descripcion,
            'comentarios': data.comentarios,
            'largo_solicitado': data.largo_solicitado,
            'kilos_solicitados': data.kilos_solicitados,
            'descuento': data.descuento,
            'total': data.total,
            'total_previo': data.total_previo
        }, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            }
        });
    },


    DeleteByQuot(id, auth) {
        return Api.delete(END_POINT_DELETEQUOT + '/' + id, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            },
        });
    }


}